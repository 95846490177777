let types = [
    'font',
    'interval',
    'img'
];

function specialLoad() {
    let body = $('html');
    let panelView = localStorage.getItem('helpView');
    if (panelView === '1') {
        $('body').addClass('specialView');
    }

    types.forEach(type => {
        let param = localStorage.getItem(type);
        if (param) {
            body.addClass(type + '-' + param);
        }
        if(type === 'img' && param === 'none') {
            $("#viewImg").removeAttr('checked');
        }
    })
}

function special(type, val) {
    let body = $('html');
    let param = localStorage.getItem(type);
    body.removeClass(type + '-' + param).addClass(type + '-' + val);
    localStorage.setItem(type, val);
}

function specialClear() {
    let body = $('html');
    types.forEach(val => {
        let param = localStorage.getItem(val);
        body.removeClass(val + '-' + param);
        localStorage.removeItem(val);
    })
}

$(function() {
    specialLoad();

    $('#special li').click(function() {
        let el = $(this);
        let type = el.data('type');
        let val = el.data('val');
        special(type, val);
    });

    $('#special #viewImg').change(function() {
        let el = $(this);
        let type = el.data('type');
        let val = el.data('val');
        let viewImg = $("#viewImg:checked" ).length;
        if(!viewImg) {
            special(type, val);
        } else {
            special(type, 'yes');
        }
    });

    $('#special #clear').click(function() {
        specialClear();
    });

    $('#openVisualHelp').click(function() {
        let val = localStorage.getItem('helpView');
        if (val === '1') {
            localStorage.setItem('helpView', '0');
            $('body').removeClass('specialView');
        } else {
            localStorage.setItem('helpView', '1');
            $('body').addClass('specialView');
        }
    })

});